import BlockUserButton from "../../react_components/user/mobile/show/block_user_button";
import FriendFollowModule from "../../react_components/user_relationships/friend_follow_module";
import AuthorDropdown from "../../react_components/author_dropdown";

import BlockUserStore from "../../react_stores/user/show/block_user_store";
import FriendFollowModuleStore from "../../react_stores/user_relationships/friend_follow_module_store";

export const UserShowComponents = {
  BlockUserButton,
  FriendFollowModule,
  AuthorDropdown,
};

export const UserShowStores = {
  BlockUserStore,
  FriendFollowModuleStore,
};
