/**
 * This helper module is used to activate the Flight component MobileConfirmationMenu.
 */

/**
 * Show the mobile confirmation menu, and upon confirmation by user, perform the
 * onConfirmCallback.
 *
 * @param {[String]} channel "channel" (Flight event namespace) to specify
 *                           which confirmation menu to display. Must match
 *                           `channel` attribute of the MobileConfirmationMenu
 *                           Flight component instance you want to respond.
 *                           This partially duplicates logic supplied by
 *                           the `trigger` function from utility_events.js;
 *                           re-using it would require packaging that mixin as
 *                           an importable module.
 * @param  {[Function]} onConfirmCallback callback to perform upon user confirmation
 */
export const showConfirmationMenu = (channel, onConfirmCallback) => {
  const el = $(document);

  // show confirmation menu
  el.trigger(`requestShow.${channel}`);

  // wait for confirmation
  el.one(`listItemSelected.${channel}`, (event, data) => {
    if (data &&
        (data.action === "delete" ||
         data.action === "confirm")) {
      onConfirmCallback();
    }
  });
};
