// React component for dropdown list on the Author profile mobile pages.
// Currently, only supports flagging an author's account.
import { Component, PropTypes } from "react";
import Dropdown from "./dropdown";

class AuthorDropdown extends Component {

  renderDropdownList() {
    return(
      <div className="authorModuleDropdownMenu">
        <a className="authorModuleDropdownMenu__dropdownLink"
            href={this.props.flagAbuseUrl}
            rel="nofollow"
            data-testid="flagAbuse">
            Report this account
        </a>
      </div>
    );
  }

  renderDropdownTrigger() {
    return(
      <div className="authorModuleDropdownTrigger" />
    );
  }

  render() {
    return (
      <Dropdown trigger={this.renderDropdownTrigger()}
                triggerEventType={Dropdown.TriggerEventTypes.CLICK}
                tooltipText="More"
                bemModifiers="authorModule"
                fallbackUrl={this.props.flagAbuseUrl}
                children={this.renderDropdownList()} />
    );
  }
};

AuthorDropdown.defaultProps = {
  flagAbuseUrl: "",
};

AuthorDropdown.propTypes = {
  flagAbuseUrl: PropTypes.string.isRequired,
};

export default AuthorDropdown;