// See documentation on https://www.goodreads.com/style_guide

import GrComponentFactory from "../../gr_component_factory";
import { PropTypes } from "react";
import { omit } from "lodash";
import { showConfirmationMenu } from "../../../modules/mobile_confirmation_menu_helper";

/**
 * Renders a button that is capable of displaying a confirmation popup.
 *
 * Any button element prop can be passed into this component.
 */

const NOT_BUTTON_ELEMENT_PROPS = ["buttonText", "action", "confirmationMenu"];

export default GrComponentFactory.createClass({
  displayName: "ButtonWithConfirmationMenu",
  propTypes: {
    buttonText: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    confirmationMenuChannel: PropTypes.string.isRequired,
  },

  buttonClicked() {
    showConfirmationMenu(this.props.confirmationMenuChannel,
                         () => { this.props.action(); });
  },

  render() {
    return <button {...omit(this.props, NOT_BUTTON_ELEMENT_PROPS)}
                   onClick={this.buttonClicked}>
      {this.props.buttonText}
    </button>;
  },
});
