// MW component for block/unblock user on User Show page
import Reflux from "reflux";
import { PropTypes } from "react";
import GrComponentFactory from "../../../gr_component_factory";
import ButtonWithConfirmationMenu from "../button_with_confirmation_menu";
import Spinner from "../../../spinner";
import BlockUserActions from "../../../../react_actions/user/show/block_user_actions";
import BlockUserStore from "../../../../react_stores/user/show/block_user_store";

export default GrComponentFactory.createClass({
  displayName: "BlockUserButton",

  propTypes: {
    displayUserId: PropTypes.number.isRequired,
  },

  mixins: [Reflux.connect(BlockUserStore, "blockUser")],

  renderButton() {
    if (this.state.blockUser.blockInProgress) {
      return (
        <div className="u-fullWidth">
          <Spinner />
        </div>
      );
    } else {
      let buttonText, channel;
      if (this.state.blockUser.isBlocked) {
        buttonText = "Unblock this user";
        channel = `confirmUnblock${this.props.displayUserId}`;
      } else {
        buttonText = "Block this user";
        channel = `confirmBlock${this.props.displayUserId}`;
      }
      return (
        <ButtonWithConfirmationMenu
          action={() => BlockUserActions.blockMember(this.props.displayUserId)}
          buttonText={buttonText}
          className="blockUserButton gr-buttonAsLink gr-buttonAsLink--danger"
          confirmationMenuChannel={channel}
        />
      );
    }
  },

  render() {
    return (
      <div className="blockUserButtonContainer">
        {this.renderButton()}
      </div>
    );
  },
});
